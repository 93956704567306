var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-6" },
      [
        _c(
          "small",
          { staticClass: "d-block text-uppercase font-weight-bold mb-3" },
          [_vm._v("Tooltips")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.left",
                modifiers: { hover: true, left: true },
              },
            ],
            staticClass: "btn-tooltip",
            attrs: { size: "sm", type: "primary", title: "Tooltip on left" },
          },
          [_vm._v("On left\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.top",
                modifiers: { hover: true, top: true },
              },
            ],
            staticClass: "btn-tooltip",
            attrs: { size: "sm", type: "primary", title: "Tooltip on top" },
          },
          [_vm._v("On top\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.bottom",
                modifiers: { hover: true, bottom: true },
              },
            ],
            staticClass: "btn-tooltip",
            attrs: { size: "sm", type: "primary", title: "Tooltip on bottom" },
          },
          [_vm._v("On bottom\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.right",
                modifiers: { hover: true, right: true },
              },
            ],
            staticClass: "btn-tooltip",
            attrs: { size: "sm", type: "primary", title: "Tooltip on right" },
          },
          [_vm._v("On right\n        ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-6 mt-4 mt-lg-0" },
      [
        _c(
          "small",
          { staticClass: "d-block text-uppercase font-weight-bold mb-3" },
          [_vm._v("Popovers")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.left",
                value:
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
                expression:
                  "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
                modifiers: { hover: true, left: true },
              },
            ],
            attrs: { size: "sm", type: "default", title: "Popover On Left" },
          },
          [_vm._v("On left\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.left",
                value:
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
                expression:
                  "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
                modifiers: { hover: true, left: true },
              },
            ],
            attrs: { size: "sm", type: "default", title: "Popover On Left" },
          },
          [_vm._v("On left\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.top",
                value:
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
                expression:
                  "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
                modifiers: { hover: true, top: true },
              },
            ],
            attrs: { size: "sm", type: "default", title: "Popover On Top" },
          },
          [_vm._v("On top\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.right",
                value:
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
                expression:
                  "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
                modifiers: { hover: true, right: true },
              },
            ],
            attrs: { size: "sm", type: "default", title: "Popover On right" },
          },
          [_vm._v("On right\n        ")]
        ),
        _c(
          "base-button",
          {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.bottom",
                value:
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus.",
                expression:
                  "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
                modifiers: { hover: true, bottom: true },
              },
            ],
            attrs: { size: "sm", type: "default", title: "Popover On bottom" },
          },
          [_vm._v("On bottom\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }